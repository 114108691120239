import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors";
import Other from "../templates/other";

export const query = graphql`
  query AgreementQuery {
    allServices: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        slug {
          current
        }
      }
    }
    magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityCategory(sort: { fields: title }) {
      nodes {
        title
        _id
      }
    }
    blocks: sanityOther(_id: { eq: "ostatni" }) {
      _rawObchodniPodminky
    }
  }
`;

const BussinesAgreementPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  return (
    <Other
      data={data}
      seo={{ title: "Obchodní podmínky", description: "Obchodní podmínky Beauty Body Clinic" }}
      dataKey={"_rawObchodniPodminky"}
    />
  );
};

export default BussinesAgreementPage;
